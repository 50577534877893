import React, {Suspense} from "react";
import AppLayout from "./components/appLayout/AppLayout";
import {createBrowserRouter, Navigate,} from "react-router-dom";
import Crops from "./pages/cropDownload/Crops";
import DeviceManagement from "./pages/devices/DeviceManagement";
import {UserRole} from "./common/userRole";

const NotFound = React.lazy(() => import("./pages/notFound/NotFound"));
const TrainRoute = React.lazy(() => import("./pages/trainRoute/TrainRoute"));
const SystemUsage = React.lazy(() => import("./pages/systemUsage/SystemUsage"));
const QueueControl = React.lazy(
  () => import("./pages/queueControl/QueueControl")
);
const Notifications = React.lazy(
  () => import("./pages/notifications/Notifications")
);
const Management = React.lazy(() => import("./pages/management/Management"));
const SystemCnfg = React.lazy(() => import("./pages/systemsCnfg/SystemsCnfg"));
const PerformanceReport = React.lazy(
  () => import("./pages/performanceReport/PerformanceReport")
);
// @ts-ignore


export const routes =(role: UserRole) => {
  const r = [
  {
    path: "/",
    element: <AppLayout />,
   /* errorElement: (
      <Suspense fallback={<div>Loading...</div>}>
        <NotFound />
      </Suspense>
    ),*/
    children: [
      {
        path: "/",
        element: <Navigate to={"performanceReport"} />,
      },
      {
        path: "performanceReport",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <PerformanceReport />
          </Suspense>
        ),
      },
      {
        path: "trainRoute",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <TrainRoute />
          </Suspense>
        ),
      },
      {
        path: "systemUsage",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <SystemUsage />
          </Suspense>
        ),
      },
      {
        path: "queueControl",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <QueueControl />
          </Suspense>
        ),
      },
      {
        path: "notifications",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Notifications />
          </Suspense>
        ),
      },
      {
        path: "cropsDownload",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Crops />
          </Suspense>
        ),
      },
    ],
  },
 {
    path: "/",
    element: <AppLayout filter={false} />,
    errorElement: (
      <Suspense fallback={<div>Loading...</div>}>
        <NotFound />
      </Suspense>
    ),
    children: [
      {
        path: "/management",
        element: role === UserRole.super_user ?(
          <Suspense fallback={<div>Loading...</div>}>
            <Management />
          </Suspense>
        ) : (
          <Navigate to={'/performanceReport'}/>
        ),
      },
      {
        path: "/devices",
        element:
          <Suspense fallback={<div>Loading...</div>}>
            <DeviceManagement />
          </Suspense>
        ,
      },
      {
        path: "/systemsCnfg",
        element:
          <Suspense fallback={<div>Loading...</div>}>
            <SystemCnfg />
          </Suspense>
        ,
      },
    ],
  },
]
  return createBrowserRouter(r)
}
