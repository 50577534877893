import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import UpdateDialog from "../updateDialog/updateDialog";

import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridEventListener,
  GridRenderCellParams,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridSelectionModel,
  GridRowParams,
  GridRowsProp,
  GridToolbarContainer,
  MuiEvent,
  useGridApiContext,
  useGridApiRef,
} from "@mui/x-data-grid";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import styles from "../usersTable/usersTable.module.scss";
import deviceManagementService from "../../client-api/services/deviceManagement";
import { useQueryClient } from "@tanstack/react-query";
import {DateTime} from "luxon";

interface Device {
  name: string;
  connection_state: string;
  last_active: string;
  id: number;
  tags: object;
  status: string;
}

interface EditToolbarProps {
  rows: GridRowsProp[];
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
}

function renderSelect(
  params: GridRenderCellParams<string[]>,
  options: string[],
  multiple: boolean
) {
  return SelectEditInputCell(params, true, multiple, options);
}

function renderSelectEditInputCell(
  params: GridRenderCellParams<string[]>,
  options: string[],
  multiple: boolean
) {
  return SelectEditInputCell(params, false, multiple, options);
}

function SelectEditInputCell(
  props: GridRenderCellParams<string[]> | any,
  readonly: boolean,
  multiple: boolean,
  options: string[]
) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    apiRef.current.setEditCellValue({ id, field, value: event.target.value });
  };

  let v = value;
  if (v === undefined) {
    if (multiple) {
      v = [];
    } else {
      v = "";
    }
  }

  return (
    <Select
      readOnly={readonly}
      multiple={multiple}
      style={{ width: "100%" }}
      value={v}
      onChange={handleChange}
    >
      {options.map((name: string) => (
        <MenuItem id={name} key={name} value={name}>
          {name}
        </MenuItem>
      ))}
    </Select>
  );
}

function EditToolbar(props: EditToolbarProps) {
  const handleClick = () => {
    const id = props.rows.length;
    props.setRows((oldRows) => [
      ...oldRows,
      { id, name: "", email: "", companies: [], role: "" },
    ]);
    props.setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

export default function FullFeaturedCrudGrid() {
  const devices = deviceManagementService.GetDevices();
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const addDeviceMutation = deviceManagementService.AddDeviceTags();
  const queryClient = useQueryClient();
  const apiRef = useGridApiRef();
  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    await addDeviceMutation.mutate(JSON.stringify(newRow), {
      onSuccess: () => {
        const updatedRow = { ...newRow, tags: { device_tags: newRow.tags } };

        const arr = devices.data.map((row: any) =>
          row.id === newRow.id ? updatedRow : row
        );

        queryClient.setQueryData(["fleetManagement"], arr);
        setRowModesModel({
          ...rowModesModel,
          [newRow["id"]]: { mode: GridRowModes.View },
        });
      },
    });

    return newRow;
  };

  const handleBtnClick = () => {
    console.log(selectionModel);
  };

  const columns: GridColumns = [
    {
      field: "id",
      headerName: "Device ID",
      flex: 3,
      editable: false,
    },
    {
      field: "loco_name",
      headerName: "Device Name",
      flex: 3,

      editable: false,
    },
    {
      field: "connection_state",
      headerName: "IS Connected",
      type: "string",
      editable: false,
      flex: 3,
    },
    {
      field: "last_active",
      headerName: "Last Active",
      type: "string",
      editable: false,
      valueGetter: (params) =>
         params.value  ? DateTime.fromMillis(params.value *1000).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) : "--",
      flex: 3,
    },
    /*{
      field: "desired",
      headerName: "Update",
      type: "string",
      valueGetter: (params) =>
        params.value["update_info"] ? params.value["update_info"].fileName : "",
      editable: true,
      flex: 3,
    },*/
   /* {
      field: "tags",
      headerName: "Tags",
      type: "string",
      valueGetter: (params) =>
        JSON.stringify(params.value),
      editable: true,
      flex: 3,
    },*/

    /*{
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      // @ts-ignore
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            // @ts-ignore
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            // @ts-ignore
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          // @ts-ignore
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },*/
  ];

  function mergeArrays(arr1: any, arr2: any) {
    const mergedArray = [...arr1, ...arr2];
    const uniqueData = [
      ...mergedArray
        .reduce((map, obj) => map.set(obj.id, obj), new Map())
        .values(),
    ];
    return uniqueData;
  }

  const [selectionModel, setSelectionModel] =
    React.useState<GridSelectionModel>([]);

  return (
    <Box className={styles.dataGrid}>
{/*
      <UpdateDialog selectedIds={selectionModel} />
*/}

      <DataGrid
        checkboxSelection={false}
        rows={devices.data}
        //rows={mergeArrays(devices.data, newRows)}
        columns={columns}
        editMode="row"
        loading={devices.isRefetching || devices.isLoading}
        rowModesModel={rowModesModel}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
        onSelectionModelChange={(newSelection) => {
          setSelectionModel(newSelection);
        }}
        selectionModel={selectionModel}
      />
    </Box>
  );
}
