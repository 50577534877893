import { DateTime } from "luxon";
import { makeAutoObservable } from "mobx";
import { FilterProps } from "../common/filterProps";

export const DEFAULT_FILTER = {
  dateFilter: {
    startDate: DateTime.now().startOf("day"),
    endDate: DateTime.now(),
    key: "selection",
    label: "Today",
  },
  selectedSystem: "",
  selectedClass: "All",
  location: "All",
  video_source: "All",
  systemType: 1,
  measurement: "metric",
};

interface MapDrawerData {
  date: number;
  classification: string;
  startDistance: number;
  duration: number;
  speed: number;
  video_name: string;
  thumbnail_url: string;
}

type direction = "asc" | "desc" | undefined;

class UiStore {
  currentPage: string = "performanceReport";
  filter: FilterProps = DEFAULT_FILTER;
  filterApplyButtonDisabled: boolean = false;
  mapDrawer: { open: boolean; data: MapDrawerData } = {
    open: false,
    data: {
      date: 0,
      classification: "Person",
      startDistance: 0,
      duration: 0,
      speed: 0,
      video_name: "",
      thumbnail_url: "",
    },
  };
  detectionsTablePage: number = 1;
  detectionsTableRowsPerPage: number = 20;
  detectionTableSortDirection: direction = "desc";
  lastUpdate: DateTime | undefined = undefined;
  csrfToken = "";

  constructor() {
    makeAutoObservable(this);
  }

  getCurrentPage() {
    return this.currentPage;
  }

  setCurrentPage(page: string) {
    this.currentPage = page;
  }

  setStartDate(date: DateTime) {
    this.filter.dateFilter.startDate = date;
  }

  setEndDate(date: DateTime) {
    this.filter.dateFilter.endDate = date;
  }

  getStartDate() {
    return this.filter.dateFilter.startDate;
  }

  getEndDate() {
    return this.filter.dateFilter.endDate;
  }

  setDateFilter(
    startDate: DateTime,
    endDate: DateTime,
    label: string = this.filter.dateFilter.label
  ) {
    this.filter.dateFilter = {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
      label: label,
    };
  }

  getDateFilter() {
    return this.filter.dateFilter;
  }

  getSelectedSystem() {
    return this.filter.selectedSystem;
  }

  getSelectedSystemType() {
    return this.filter.systemType;
  }

  getSelectedSystemMeasurement() {
    return this.filter.measurement;
  }

  setSelectedSystem(selectedSystem: string) {
    this.filter.selectedSystem = selectedSystem;
  }

  getSelectedClass() {
    return this.filter.selectedClass;
  }

  setSelectedClass(val: string) {
    this.filter.selectedClass = val;
  }

  getFilterLocation() {
    return this.filter.location;
  }

  setFilterLocation(value: string) {
    this.filter.location = value;
  }

  getFilter() {
    return this.filter;
  }

  setFilter(filter: FilterProps) {
    this.filter = filter;
    this.detectionsTablePage = this.detectionsTablePage + 1;
  }

  setFilterApplyButtonDisabled(disabled: boolean) {
    this.filterApplyButtonDisabled = disabled;
  }

  getFilterApplyButtonDisabled() {
    return this.filterApplyButtonDisabled;
  }

  setMapDrawerOpen(drawerOpen: boolean) {
    this.mapDrawer = { ...this.mapDrawer, open: drawerOpen };
  }

  getMapDrawerOpen() {
    return this.mapDrawer.open;
  }

  setMapDrawerData(data: MapDrawerData) {
    this.mapDrawer = { ...this.mapDrawer, data: data };
  }

  getMapDrawerData() {
    return this.mapDrawer.data;
  }

  getDetectionsTablePage() {
    return this.detectionsTablePage;
  }

  setDetectionsTablePage(detectionsTablePage: number) {
    this.detectionsTablePage = detectionsTablePage;
  }

  getDetectionsTableRowsPerPage(): number {
    return this.detectionsTableRowsPerPage;
  }

  setDetectionsTableRowsPerPage(rowPerPage: number) {
    this.detectionsTableRowsPerPage = rowPerPage;
  }

  getDetectionTableSortDirection() {
    return this.detectionTableSortDirection;
  }

  flipDetectionTableSortDirection() {
    this.detectionTableSortDirection =
      this.detectionTableSortDirection === "asc" ? "desc" : "asc";
    this.detectionsTablePage = 1;
  }

  setLastUpdate(lastUpdated: DateTime) {
    this.lastUpdate = lastUpdated;
  }

  getLastUpdate() {
    return this.lastUpdate;
  }

  getCsrfToken() {
    return this.csrfToken;
  }

  setCsrfToken(token: string) {
    this.csrfToken = token;
  }

  getFilterVideoSource() {
    return this.filter.video_source;
  }

  setFilterVideoSource(video_source: string) {
    this.filter.video_source = video_source;
  }
}

const uiStore = new UiStore();

export default uiStore;
